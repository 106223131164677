import React, {useState} from "react";
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n";

import Layout from "../components/Layout";
import HelpMenu from "../components/HelpMenu";
import QuickStartEN from "../components/QuickStartEN";
import QuickStartDE from "../components/QuickStartDE";
import QuickStartFR from "../components/QuickStartFR";

export default function HelpPage() {
    const { locale } = useLocalization();

    return (
        <Layout>
            {/* <main className="static help-wrap">          
                <div className="hamburger-help-wrap mobile-only">                      
                    <Link to="/help" className="hamburger-help"></Link>
                </div>
                <div className="help-menu"><HelpMenu /></div>                
            </main> */}

            <main className="static quick-start">
                <div className="row">
                    {(locale === "en") && <QuickStartEN /> }
                    {(locale === "de") && <QuickStartDE /> }
                    {(locale === "fr") && <QuickStartFR /> }
                </div>
            </main> 
        </Layout>
    );
}
